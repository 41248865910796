
import Vue from 'vue'
import CardsList from './components/CardsList.vue'
import { mapMutationsTyped } from './store'
import { repositoryGetter } from '@netvision/lib-api-repo'

export default Vue.extend({
  name: 'App',
  components: {
    CardsList
  },
  beforeMount() {
    this.$store.commit('reset')
    this.setValue(['spaParent', this.$parent])
    this.setValue(['spaProps', this.$parent?.props])
    this.setValue(['api', repositoryGetter(this.$parent?.props?.lib)])
  },
  methods: {
    ...mapMutationsTyped(['reset', 'setValue'])
  }
})
