export const insensitify = (string: string) => {
  return string
    .split('')
    .map((char) => {
      if (char.match(/[a-zа-я]/i)) {
        return `[${char.toLowerCase()}${char.toUpperCase()}]`
      } else {
        return char
      }
    })
    .join('')
}
export const uuid = () => {
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
    const r = (Math.random() * 16) | 0
    const v = c === 'x' ? r : (r & 0x3) | 0x8
    return v.toString(16)
  })
}

const timers = {} as any
export const debounce = (callback: Function, timeout = 300, key = 'main') => {
  clearTimeout(timers[key])
  timers[key] = setTimeout(callback, timeout)
}
