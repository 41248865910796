
import Vue from 'vue'
import { mapStateTyped } from '@/store'
import { history, SearchParams } from '@netvision/lib-history'
import { CubeFilter } from '@netvision/lib-api-repo'
import Mixins from '../../mixins'

const DEFAULT_CUBE_NAME = 'Objects'

export default Mixins.extend({
  name: 'QueryFilters',
  props: {
    value: Object,
    canI: Function,
    servicePath: String,
    entityTypes: Array as () => string[],
    isEmpty: Boolean
  },
  data() {
    return {
      localEntityTypes: this.entityTypes,
      currentAttrFilter: null as null | string[],
      screenWidth: 0
    }
  },
  computed: {
    ...mapStateTyped(['spaProps']),
    cubeName(): string {
      return this.spaProps?.cubeName || DEFAULT_CUBE_NAME
    },
    isShowClearIcon(): boolean {
      // @ts-ignore
      return (this.typeFilters !== null && this.localEntityTypes?.length) || this.qFilters?.map(i => i.queryKey).some(key => this.value[key])
    }
  },
  mounted() {
    this.defineSubFilters()
    this.getScreenWidth()
    window.addEventListener('resize', this.getScreenWidth)
  },
  watch: {
    entityTypes(val) {
      this.localEntityTypes = val
    },
    localEntityTypes(val) {
      this.$emit('update:entityTypes', val)
    },
    value() {
      this.defineSubFilters()
    }
  },
  asyncComputed: {
    qFilters: {
      async get(): Promise<Filters['qFilters'] | []> {
        if (this.spaProps !== undefined) {
          const qFilters: Filters['qFilters'] = this.spaProps.filters
            ? [...(this.spaProps?.filters?.qFilters || [])] || []
            : []

          return await Promise.all(
            qFilters.map(async (qFilter) => {
              const options = await Promise.all(
                qFilter.options.map(async (option) => ({
                  ...option,
                  count: await this.fetchCount([
                    {
                      member: `${this.cubeName}.${
                        option.queryKey || qFilter.queryKey
                      }`,
                      operator: 'equals',
                      values: [option.value]
                    },
                    {
                      member: `${this.cubeName}.servicePath`,
                      operator: 'equals',
                      values: ['']
                    }
                  ])
                }))
              )
              return {
                ...qFilter,
                options
              }
            })
          )
        }
        return []
      },
      watch: ['servicePath']
    },
    typeFilters: {
      async get(): Promise<Filters['typeFilters'] | null> {
        if (this.spaProps !== undefined) {
          const { typeFilters } = this.spaProps.filters || {}
          if (!typeFilters) return null
          const spFilter: CubeFilter[] = []

          spFilter.push({
            member: `${this.cubeName}.servicePath`,
            operator: 'equals',
            values:
              typeof this.servicePath === 'string' &&
              this.servicePath.length > 2
                ? [this.servicePath]
                : ['']
          })

          const countedOptions = await Promise.all(
            typeFilters.options.map(async (option) => ({
              ...option,
              count: await this.fetchCount([
                {
                  member: `${this.cubeName}.type`,
                  operator: 'equals',
                  values: [option.type]
                },
                ...spFilter
              ])
            }))
          )

          return {
            ...typeFilters,
            options: countedOptions.filter(({ count }) => count > 0)
          }
        }
        return null
      },
      watch: ['servicePath']
    },
    otherFilters: {
      async get(): Promise<Filters['otherFilters'] | null> {
        if (this.spaProps !== undefined) {
          const otherFilters = this.spaProps.filters
            ? [...(this.spaProps?.filters?.otherFilters || [])] || null
            : null
          if (!otherFilters) return null
          const allowedOtherFilters = []
          for (const otherFilter of otherFilters) {
            ;(await this.canI(otherFilter.scopes)) &&
              allowedOtherFilters.push(otherFilter)
          }

          const countedOptions = await Promise.all(
            allowedOtherFilters.map(async (option) => ({
              ...option,
              count:
                option.haveCounter !== false
                  ? await this.fetchCount([
                      {
                        member: `${this.cubeName}.${option.queryKey}`,
                        // @ts-ignore
                        operator: option.operator || 'equals',
                        values: [option.value]
                      }
                    ])
                  : 0
            }))
          )
          return countedOptions
        }
        return []
      }
    }
  },
  methods: {
    clearFilters() {
      Promise.resolve()
        .then(() => {
          this.localEntityTypes = []
        })
        .then(() => {
          // @ts-ignore
          this.qFilters.forEach(filter => {
            this.$delete(this.value, filter.queryKey)
            filter.options.forEach((option: Record<string, any>) => {
              this.$set(this.value, option.value, [])
            })
          })
        })
    },
    defineSubFilters() {
      const { type } = SearchParams.parse(history.location.search)
      if (typeof type !== 'string') {
        this.currentAttrFilter = []
        return false
      }
      const types = type.split(',')
      this.currentAttrFilter = types.reduce<string[] | null>((acc, next) => {
        const applicableTypes = this.spaProps?.filters?.qFilters.reduce<
          string[]
        >((acc, { applicableToTypes, attributeName }) => {
          if (applicableToTypes.includes(next)) {
            acc.push(attributeName)
          }
          return acc
        }, [])
        if (applicableTypes?.length) {
          acc = (acc || []).concat(applicableTypes)
        }
        return acc
      }, null)
    },
    menuToggle(ref: string, event: EventInit) {
      let menu = this.$refs[ref] as Vue & {
        toggle: (event: EventInit) => {}
      }
      menu = Array.isArray(menu) ? menu[0] : menu
      if (menu !== undefined) {
        menu!.toggle(event)
      }
    },
    toggleFilter(filters: string[], filterValue: string, isTypeFilter = false) {
      if (filters.includes(filterValue)) {
        filters.splice(filters.indexOf(filterValue), 1)
        if (isTypeFilter) {
          // @ts-ignore
          const applicableFilters: Filters['qFilters'] = this.qFilters.filter(
            (filter: any) => filter.applicableToTypes.includes(filterValue)
          )
          applicableFilters.forEach((filter) => {
            setTimeout(() => {
              this.$set(this.value, filter.queryKey, [])
            })
          })
        }
      } else {
        filters.push(filterValue)
      }
    },
    toggleQFilter(attributeName: string, value: string, queryKey: string) {
      if (this.value[queryKey] !== undefined) {
        this.toggleFilter(this.value[queryKey], value)
      } else {
        this.currentAttrFilter = Array.from(
          new Set([...(this.currentAttrFilter || []), attributeName])
        )
        this.$set(this.value, queryKey, [value])
      }
    },
    getQFilterClassName(queryKey: string, value: string) {
      return [
        this.value[queryKey]
          ? this.value[queryKey].includes(value)
            ? !this.isEmpty
              ? 'p-button-outlined'
              : 'p-button-outlined p-button-danger'
            : 'p-button-blurred'
          : 'p-button-blurred'
      ]
    },
    checkApplicability(
      attributeName: string,
      applicableToTypes: string[] | undefined
    ) {
      return (
        !applicableToTypes || this.currentAttrFilter?.includes(attributeName)
      )
    },
    getScreenWidth() {
      this.screenWidth = window.innerWidth
    }
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.getScreenWidth)
  }
})
