(function webpackUniversalModuleDefinition(root, factory) {
	if(typeof exports === 'object' && typeof module === 'object')
		module.exports = factory(require("primevue/inputtext"), require("primevue/paginator"), require("primevue/slider"), require("primevue/inputswitch"), require("primevue/dropdown"), require("primevue/tooltip"), require("single-spa-vue"), require("@netvision/lib-history"), require("vue-router"), require("primevue/toastservice"), require("vue-i18n"), require("primevue/progressspinner"), require("vue"), require("@netvision/lib-api-repo"), require("primevue/selectbutton"), require("primevue/multiselect"), require("primevue/toast"), require("primevue/progressbar"), require("primevue/overlaypanel"), require("primevue/button"), require("primevue/menu"));
	else if(typeof define === 'function' && define.amd)
		define(["primevue/inputtext", "primevue/paginator", "primevue/slider", "primevue/inputswitch", "primevue/dropdown", "primevue/tooltip", "single-spa-vue", "@netvision/lib-history", "vue-router", "primevue/toastservice", "vue-i18n", "primevue/progressspinner", "vue", "@netvision/lib-api-repo", "primevue/selectbutton", "primevue/multiselect", "primevue/toast", "primevue/progressbar", "primevue/overlaypanel", "primevue/button", "primevue/menu"], factory);
	else {
		var a = typeof exports === 'object' ? factory(require("primevue/inputtext"), require("primevue/paginator"), require("primevue/slider"), require("primevue/inputswitch"), require("primevue/dropdown"), require("primevue/tooltip"), require("single-spa-vue"), require("@netvision/lib-history"), require("vue-router"), require("primevue/toastservice"), require("vue-i18n"), require("primevue/progressspinner"), require("vue"), require("@netvision/lib-api-repo"), require("primevue/selectbutton"), require("primevue/multiselect"), require("primevue/toast"), require("primevue/progressbar"), require("primevue/overlaypanel"), require("primevue/button"), require("primevue/menu")) : factory(root["primevue/inputtext"], root["primevue/paginator"], root["primevue/slider"], root["primevue/inputswitch"], root["primevue/dropdown"], root["primevue/tooltip"], root["single-spa-vue"], root["@netvision/lib-history"], root["vue-router"], root["primevue/toastservice"], root["vue-i18n"], root["primevue/progressspinner"], root["vue"], root["@netvision/lib-api-repo"], root["primevue/selectbutton"], root["primevue/multiselect"], root["primevue/toast"], root["primevue/progressbar"], root["primevue/overlaypanel"], root["primevue/button"], root["primevue/menu"]);
		for(var i in a) (typeof exports === 'object' ? exports : root)[i] = a[i];
	}
})(window, function(__WEBPACK_EXTERNAL_MODULE__025a__, __WEBPACK_EXTERNAL_MODULE__0d70__, __WEBPACK_EXTERNAL_MODULE__12a1__, __WEBPACK_EXTERNAL_MODULE__199d__, __WEBPACK_EXTERNAL_MODULE__3746__, __WEBPACK_EXTERNAL_MODULE__3b23__, __WEBPACK_EXTERNAL_MODULE__4171__, __WEBPACK_EXTERNAL_MODULE__4ebd__, __WEBPACK_EXTERNAL_MODULE__6389__, __WEBPACK_EXTERNAL_MODULE__68f9__, __WEBPACK_EXTERNAL_MODULE__85b3__, __WEBPACK_EXTERNAL_MODULE__8aba__, __WEBPACK_EXTERNAL_MODULE__8bbf__, __WEBPACK_EXTERNAL_MODULE__9463__, __WEBPACK_EXTERNAL_MODULE_a28b__, __WEBPACK_EXTERNAL_MODULE_be9f__, __WEBPACK_EXTERNAL_MODULE_d26e__, __WEBPACK_EXTERNAL_MODULE_d833__, __WEBPACK_EXTERNAL_MODULE_dffb__, __WEBPACK_EXTERNAL_MODULE_f095__, __WEBPACK_EXTERNAL_MODULE_f4f3__) {
return 