
import Vue from 'vue'
import { mapState } from 'vuex'
export default Vue.extend({
  name: 'Card',
  props: {
    entity: Object,
    emitter: Object,
    isCompactView: Boolean,
    mainAction: Object,
    meta: Object
  },
  data() {
    return {
      unmount: () => {}
    }
  },
  computed: {
    ...mapState(['spaParent'])
  },
  mounted() {
    this.unmount = this.mountCard()
  },
  beforeDestroy() {
    this.unmount()
  },
  methods: {
    mountCard() {
      let ref = this.$refs[`card-${this.entity.id}`]
      ref = Array.isArray(ref) ? ref[0] : ref
      const objectCard = this.spaParent.areas.find(
        (area: any) => area.name === 'Card'
      )
      if (objectCard && ref) {
        return this.spaParent.mountChildren(
          ref,
          objectCard.children.map((e: any) => {
            return {
              ...e,
              props: {
                entity: this.entity,
                emitter: this.emitter,
                isCompactView: this.isCompactView,
                toast: this.$toast,
                mainAction: this.mainAction,
                meta: this.meta
              }
            }
          })
        )
      }
    }
  }
})
