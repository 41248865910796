import './set-public-path'
import Vue from 'vue'
import singleSpaVue from 'single-spa-vue'
import VueRouter from 'vue-router'
import VueI18n from 'vue-i18n'
import Vuex from 'vuex'
import Store from './store'
import { RUSSIAN_TRANSLATIONS } from './translations/ru'
import ToastService from 'primevue/toastservice'
import Toast from 'primevue/toast'
import Paginator from 'primevue/paginator'
import Dropdown from 'primevue/dropdown'
import InputText from 'primevue/inputtext'
import Button from 'primevue/button'
import SelectButton from 'primevue/selectbutton'
import ProgressSpinner from 'primevue/progressspinner'
import OverlayPanel from 'primevue/overlaypanel'
import Slider from 'primevue/slider'
import InputSwitch from 'primevue/inputswitch'
import Tooltip from 'primevue/tooltip'
import MultiSelect from 'primevue/multiselect'
import ProgressBar from 'primevue/progressbar'
import AsyncComputed from 'vue-async-computed'
import Menu from 'primevue/menu'
import App from './App.vue'

Vue.component('ProgressBar', ProgressBar)
Vue.component('Menu', Menu)
Vue.component('Slider', Slider)
Vue.component('Toast', Toast)
Vue.component('Paginator', Paginator)
Vue.component('Dropdown', Dropdown)
Vue.component('InputText', InputText)
Vue.component('Button', Button)
Vue.component('SelectButton', SelectButton)
Vue.component('ProgressSpinner', ProgressSpinner)
Vue.component('OverlayPanel', OverlayPanel)
Vue.component('InputSwitch', InputSwitch)
Vue.component('MultiSelect', MultiSelect)
Vue.directive('tooltip', Tooltip)

Vue.use(ToastService)
Vue.use(AsyncComputed)
Vue.use(VueRouter)
Vue.use(VueI18n)
Vue.use(Vuex)

Vue.config.productionTip = false

const routes = [] as any
const router = new VueRouter({
  base: __dirname,
  mode: 'history',
  routes
})
const locale = 'ru'
const TRANSLATIONS = {
  ru: RUSSIAN_TRANSLATIONS
}
const i18n = new VueI18n({
  locale,
  messages: TRANSLATIONS
})
const store = new Vuex.Store(Store)

const vueLifecycles = singleSpaVue({
  Vue,
  appOptions: {
    router,
    i18n,
    store,
    render: (h: any) => h(App)
  }
})

export const bootstrap = vueLifecycles.bootstrap
export const mount = (props: any) => {
  if (props.domElement && props.vueAppend) {
    const el = document.createElement('div')
    props.domElement.appendChild(el)
    props.domElement = el
  }
  return vueLifecycles.mount(props)
}
export const unmount = vueLifecycles.unmount
export const update = vueLifecycles.update
