var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{ref:"container",staticClass:"cards-list-container"},[_c('transition',{attrs:{"name":"component-fade","mode":"out-in"}},[_c('div',[_c('div',{staticClass:"cards-list__header p-d-flex p-ai-end",style:({
          'border-bottom': _vm.loading
            ? '1px solid transparent'
            : '1px solid var(--text-color-secondary)',
        })},[(!_vm.isInitServicePath)?_c('Button',{staticClass:"p-button-rounded p-button-plain p-button-text p-mr-2",staticStyle:{"margin-bottom":"calc(8rem / var(--bfs))"},attrs:{"icon":"mdi mdi-18px mdi-arrow-left"},on:{"click":function($event){return _vm.goBack()}}}):_vm._e(),_c('div',{staticClass:"cards-list__title",style:({
            'border-bottom': _vm.loading
              ? '4px solid transparent'
              : '4px solid var(--primary-color)',
          }),attrs:{"data-cy":"cards-list-title"}},[_c('span',[_vm._v(_vm._s(_vm.complexObjectName || _vm.spaProps.title || _vm.$t('cardsList')))])]),(_vm.isClearable())?_c('div',{staticClass:"p-d-flex p-ai-center cards-list__unfilter"},[_c('Button',{staticClass:"p-button-blurred",attrs:{"icon":"mdi mdi-filter-off"},on:{"click":_vm.cleanFilter}})],1):_vm._e(),_c('div',{staticStyle:{"flex-grow":"1"}}),_c('Button',{staticClass:"p-button-sm p-mb-1",class:[
            (_vm.orderBy.length === 0 && 'p-button-text') || 'p-button-outlined',
          ],staticStyle:{"margin-bottom":"calc(8rem / var(--bfs))"},attrs:{"data-cy":"order-button","icon":"mdi mdi-18px mdi-sort-variant"},on:{"click":function($event){return _vm.menuToggle('orderMenu', $event)}}}),_c('OverlayPanel',{ref:"orderMenu",staticStyle:{"display":"block"},attrs:{"appendTo":"body"}},_vm._l((_vm.orderByOptions),function({ title, fieldName }){return _c('div',{key:fieldName,staticClass:"p-d-flex p-ai-center p-m-1",staticStyle:{"width":"100%"},attrs:{"data-cy":"order-option-field","title":title}},[_c('Button',{class:_vm.orderButtonClass(fieldName),staticStyle:{"border-radius":"var(--border-radius) 0 0 var(--border-radius)","border-right-width":"0"},attrs:{"data-cy":"order-option-button"},on:{"click":function($event){return _vm.toggleOrder(fieldName)}}},[_c('i',{staticClass:"mdi mdi-20px p-mr-2",class:_vm.sortIcon(fieldName)}),_c('span',{staticClass:"field-label",attrs:{"data-cy":"order-option-title"}},[_vm._v(_vm._s(title))])]),_c('Button',{class:_vm.orderButtonClass(fieldName),staticStyle:{"border-radius":"0 var(--border-radius) var(--border-radius) 0","border-left-width":"0"},attrs:{"data-cy":"order-option-clear-button","icon":"mdi mdi-18px mdi-close"},on:{"click":function($event){return _vm.dropOrderField(fieldName)}}})],1)}),0),(_vm.loading)?_c('ProgressBar',{style:({
            position: 'absolute',
            height: 'calc(2rem / var(--bfs))',
            width: '100%',
          }),attrs:{"data-cy":"line-progressbar","mode":"indeterminate"}}):_c('div',{style:({
            height: 'calc(2rem / var(--bfs))',
          })})],1),_c('AdditionalCommands',{on:{"refrash":_vm.updateAll}}),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.loading || _vm.entities.length > 0),expression:"loading || entities.length > 0"}],staticClass:"cards"},[_c('div',{staticClass:"cards__container",attrs:{"id":"camerasAnchor"}},[_c('transition-group',{staticClass:"cards__list",attrs:{"name":"list-complete","tag":"div","data-cy":"cards-list"}},[_vm._l((_vm.entities),function(card){return _c('div',{key:card.id,staticClass:"list-complete-item p-mr-1 p-mb-1"},[(card.type === 'Camera')?_c('CameraCard',{attrs:{"data-cy":"camera-cards","camera":card,"options":{
                  assignmentTypes: _vm.assignmentTypes,
                  compactViewSubscriptor: _vm.compactViewSubscriptor,
                  isCompactView: _vm.isCompactView,
                  permissionScopes: _vm.permissionScopes,
                  toast: _vm.$toast,
                  assignmentGroups: _vm.assignmentGroups,
                  analytics: _vm.analytics,
                }}}):(card.type === 'BolidDevice')?_c('BolidDeviceCard',{attrs:{"data-cy":"bolid-device-cards","bolidDevice":card,"options":{
                  isCompactView: _vm.isCompactView,
                  compactViewSubscriptor: _vm.compactViewSubscriptor,
                  toast: _vm.$toast,
                }}}):_c('Card',{attrs:{"data-cy":"complex-object-cards","entity":card,"emitter":_vm.emitter,"isCompactView":_vm.isCompactView,"meta":_vm.metaTypes[card.type] || {},"mainAction":{
                  type: 'ActionButton',
                  label: _vm.$t('open'),
                  callback: () => _vm.openComplexObject(card),
                  scopes: [],
                }}})],1)}),_vm._l((_vm.loading ? _vm.limit - _vm.entities.length : 0),function(_,index){return _c('div',{key:`skeleton-${index}`,staticClass:"p-mr-1 p-mb-1"},[_c('span',{staticClass:"card skeleton-box",staticStyle:{"height":"calc(338rem / var(--bfs))"}})])})],2)],1)]),_c('transition',{attrs:{"name":"component-fade"}},[_c('div',{staticClass:"cards__footer p-d-flex",attrs:{"data-cy":"footer"}},[_c('div',{staticClass:"p-d-flex p-col-4 p-ai-center",staticStyle:{"padding":"calc(6rem / var(--bfs))"}},[_c('div',{staticClass:"cards__view-switcher p-mr-1",staticStyle:{"min-width":"96px"},attrs:{"data-cy":"cards-view-switcher"}},[_c('SelectButton',{staticClass:"p-mr-2 p-raised",attrs:{"optionValue":"val","dataKey":"icon","options":[
                  {
                    icon: 'mdi-format-list-text',
                    datacy: 'format-list-text',
                    val: false,
                  },
                  {
                    icon: 'mdi-format-list-bulleted-square',
                    datacy: 'format-list-bulleted-square',
                    val: true,
                  },
                ]},scopedSlots:_vm._u([{key:"option",fn:function(slotProps){return [_c('i',{class:`mdi mdi-18px ${slotProps.option.icon}`,attrs:{"data-cy":`${slotProps.option.datacy}`}})]}}]),model:{value:(_vm.isCompactView),callback:function ($$v) {_vm.isCompactView=$$v},expression:"isCompactView"}})],1),(_vm.isQFiltersReady)?_c('QueryFilters',{attrs:{"data-cy":"filter-button","entityTypes":_vm.entityTypes,"canI":_vm.canI,"servicePath":_vm.servicePath,"isEmpty":_vm.entities.length === 0 && !_vm.loading},on:{"update:entityTypes":function($event){_vm.entityTypes=$event},"update:entity-types":function($event){_vm.entityTypes=$event}},model:{value:(_vm.filters),callback:function ($$v) {_vm.filters=$$v},expression:"filters"}}):_vm._e()],1),_c('div',{staticClass:"cards__paginator p-col-4",style:({
              opacity: !_vm.loading && _vm.isPaginationReady ? 1 : '.4',
              pointerEvents: !_vm.loading && _vm.isPaginationReady ? 'all' : 'none',
              minWidth: 'calc(556rem / var(--bfs))',
              padding: 'calc(6rem / var(--bfs))',
            }),attrs:{"data-cy":"paginator-block"}},[_c('Paginator',{attrs:{"first":_vm.offset,"rows":_vm.limit,"totalRecords":_vm.count,"pageLinkSize":3,"template":"FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink"},on:{"update:first":function($event){_vm.offset=$event},"update:rows":function($event){_vm.limit=$event}},scopedSlots:_vm._u([{key:"left",fn:function(){return [_c('Dropdown',{staticClass:"p-inputwrapper-fille",staticStyle:{"margin-right":"0"},attrs:{"options":Array.from(
                      new Set([6, 8, 10, 12, 15, 18, 21, _vm.limit]),
                    ).sort((a, b) => a - b),"appendTo":"body"},model:{value:(_vm.limit),callback:function ($$v) {_vm.limit=$$v},expression:"limit"}})]},proxy:true},{key:"right",fn:function(){return [_c('Button',{staticClass:"p-button-blurred",staticStyle:{"font-size":"16px"},attrs:{"type":"button","icon":"mdi mdi-18px mdi-refresh"},on:{"click":_vm.updateAll}})]},proxy:true}])})],1),_c('div',{staticClass:"cards__right-side p-col-4",staticStyle:{"flex-shrink":"1"}})])])],1)]),_c('transition',{attrs:{"name":"component-fade","mode":"out-in"}},[_c('EmptySearch',{directives:[{name:"show",rawName:"v-show",value:(_vm.entities.length === 0 && !_vm.loading),expression:"entities.length === 0 && !loading"}]})],1),_c('v-teleport-location',{attrs:{"name":"BolidSubscriptionDialog","tag":"div"}}),_c('v-teleport',{attrs:{"to":"searchTeleport"}},[_c('div',{staticClass:"entity__search",attrs:{"data-cy":"search-field"}},[_c('span',{staticClass:"p-input-raised p-input-icon-left",staticStyle:{"width":"100%"}},[_c('i',{staticClass:"mdi mdi-18px mdi-magnify"}),_c('InputText',{class:{
            'p-error':
              !_vm.loading && _vm.entities.length === 0 && _vm.searchTitle !== '',
          },staticStyle:{"width":"100%"},attrs:{"type":"text","placeholder":_vm.$t('search')},model:{value:(_vm.searchTitle),callback:function ($$v) {_vm.searchTitle=$$v},expression:"searchTitle"}})],1),(_vm.searchTitle !== '')?_c('i',{staticClass:"mdi mdi-18px mdi-close close-button",on:{"click":function($event){_vm.searchTitle = ''}}}):_vm._e()])]),_c('Toast',{attrs:{"position":"bottom-left"}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }