
import { mapStateTyped } from '@/store'
import Vue from 'vue'
export default Vue.extend({
  name: 'AdditionalCommands',
  data() {
    return {
      unmount: [] as any
    }
  },
  computed: {
    ...mapStateTyped(['spaParent', 'spaProps'])
  },
  destroyed() {
    this.unmount.forEach((e: any) => e())
  },
  mounted() {
    this.mountWidgets()
  },
  methods: {
    mountWidget(placeholder: HTMLElement, areas: any[]) {
      for (const area of areas) {
        this.unmount = [
          ...this.unmount,
          this.spaParent.mountChildren(
            placeholder,
            area.children.map((e: any) => {
              return {
                ...e,
                props: {
                  ...e.props,
                  refrash: () => {
                    this.$emit('refrash')
                  }
                }
              }
            })
          )
        ]
      }
    },
    mountWidgets() {
      const leftPlaceholder = this.$refs.leftCommands as HTMLElement
      const rightPlaceholder = this.$refs.rightCommands as HTMLElement

      const rightCommands = this.spaParent.areas.filter(
        (e: any) => e.name === 'rightCommand'
      )
      const leftCommands = this.spaParent.areas.filter(
        (e: any) => e.name === 'leftCommand'
      )

      leftCommands.length && this.mountWidget(leftPlaceholder, leftCommands)
      rightCommands.length && this.mountWidget(rightPlaceholder, rightCommands)
    }
  }
})
