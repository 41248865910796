
import Vue from 'vue'
import { mapState } from 'vuex'

interface CameraWidgetOptions {
  assignmentTypes: AssignmentType[]
  compactViewSubscriptor: (id: string) => void
  isCompactView: boolean
  permissionScopes: Map<string, string[]>
  toast: Vue
  assignmentGroups: IAssignmentGroup[]
  analytics: Assignment[]
}

export default Vue.extend({
  name: 'CameraCard',
  props: {
    camera: Object as () => Camera,
    options: Object as () => CameraWidgetOptions
  },
  data() {
    return {
      unmount: () => {}
    }
  },
  computed: {
    ...mapState(['spaParent'])
  },
  watch: {
    isCompactView() {
      this.unmount()
      this.mountCard()
    }
  },
  beforeDestroy() {
    this.unmount()
  },
  mounted() {
    this.unmount = this.mountCard()
  },
  methods: {
    mountCard() {
      let ref = this.$refs[`card-${this.camera.id}`]
      ref = Array.isArray(ref) ? ref[0] : ref
      const camCard = this.spaParent.areas.find((e: any) => e.name === 'Camera')
      if (camCard !== undefined && ref !== undefined) {
        const assignedAnalytics = this.options.analytics.filter(
          (el: any) => el.entityId === this.camera.id
        )
        return this.spaParent.mountChildren(
          ref,
          camCard.children.map((e: any) => {
            return {
              ...e,
              props: {
                ...e.props,
                camera: this.camera,
                /**
                 * @deprecated since version 2.1.0
                 */
                assignedAnalytics,
                options: this.options
              }
            }
          })
        )
      }
    }
  }
})
