export const RUSSIAN_TRANSLATIONS = {
  button: {
    ok: 'ОК',
    yes: 'Да',
    no: 'Нет',
    delete: 'Удалить',
    save: 'Сохранить',
    create: 'Создать',
    cancel: 'Отмена',
    done: 'Готово',
    createAccessCode: 'Создать код',
    deleteAccessCode: 'Удалить код',
    add: 'Добавить',
    addByCode: 'Ввести код доступа',
    onlyMineFilter: 'Только мои',
    all: 'Все',
    replace: 'Переместить',
    clear: 'Очистить',
    back: 'Назад'
  },
  filters: {
    complexObjects: 'Комплексные объекты',
    cameras: 'Камеры',
    bolidDevice: 'Устройства',
    police: 'ГИБДД',
    domain: 'Придомовое',
    commercial: 'Коммерческое',
    bg: 'БГ'
  },
  message: {
    deleteEntity: 'Удалить без возможности восстановления?'
  },
  form: {
    name: 'Название',
    choose: 'Выбрать',
    activationStatus: 'Уведомлять о статусе'
  },
  dialog: {
    editeBolidSubscription: 'Изменить уведомление о статусе',
    createBolidSubscription: 'Добавить уведомление о статусе'
  },
  cardsList: 'Список',
  emptyHistory: 'Нет истории изменения статусов',
  wrongStatus: 'Неверный статус',
  wrongStatusMessge: 'В коде статуса ошибка',
  calendarButtons: {
    today: 'Сегодня',
    yesterday: 'Вчера',
    week: 'Неделя',
    month: 'Месяц'
  },
  nothingFound: 'Ничего не найдено',
  search: 'Поиск',
  name: 'Название',
  filterByDistance: 'Фильтровать по ближайшим',
  startingPoint: 'Отсчетная точка',
  maxDistance: 'Максимальная дистанция',
  unknownValue: 'Неизвестное значение',
  all: 'Все',
  noArchive: 'У данной камеры нет архивных записей',
  detailed: 'Подробный',
  compact: 'Сжатый',
  cameras: 'Камеры',
  open: 'Открыть',
  back: 'Назад',
  emptyLabel: 'Ничего не найдено. Измените ваш запрос'
}
