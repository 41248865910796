
import BolidDeviceCard from './common/BolidDeviceCard.vue'
import CameraCard from './common/CameraCard.vue'
import EmptySearch from './common/EmptySearch.vue'
import QueryFilters from './common/QueryFilters.vue'
import AdditionalCommands from './common/AdditionalCommands.vue'
import Card from './common/Card.vue'
import mitt from 'mitt'
import { debounce } from '@/utils'
import { bolidStatuses } from '@/bolidStatuses'
import { history, SearchParams } from '@netvision/lib-history'
import { IListPayload } from '@netvision/lib-api-repo'
import Mixins from '../mixins'

const DEFAULT_NODE_ENTITIES = ['ComplexObject', 'ComplexObjectArea']
const DEFAULT_LEAF_ENTITIES = ['Camera']
const DEFAULT_SORTING_FIELDS = [
  { title: 'Название', fieldName: 'Objects.title' },
  // { title: 'Дата создания', fieldName: 'dateCreated' },
  { title: 'Объекты', fieldName: 'Objects.type' }
] as Required<IWidgetProps>['orderByFields']
const CLEAR_FILTERS = { title: '' }
const DEFAULT_CUBE_NAME = 'Objects'

// find all scoppes recoursively from Filters and collect in list
function collectScopes(filters: Record<string, any>): string[] {
  if (!filters) {
    return []
  }
  const scopes: string[] = []
  for (const key in filters) {
    if (key === 'scopes') {
      scopes.push(...filters[key])
    }
    if (Array.isArray(filters[key])) {
      for (const item of filters[key]) {
        scopes.push(...collectScopes(item))
      }
    }
  }
  return scopes
}

export default Mixins.extend({
  name: 'CardList',
  components: {
    BolidDeviceCard,
    CameraCard,
    EmptySearch,
    Card,
    QueryFilters,
    AdditionalCommands
  },
  data() {
    const query = SearchParams.parse(history.location.search)
    return {
      isListReady: false,
      isPaginationReady: false,
      orderBy: [] as string[],
      currentRequestMarker: {} as const,
      analytics: [] as IEntity[],
      assignmentGroups: null as IEntity[] | null,
      selectedType: null,
      analyticAdditionalPops: [],
      assignmentTypes: [] as AssignmentType[],
      offset: 0,
      limit: Number(query.limit) || 6,
      initLimit: Number(query.limit) || 6,
      count: 0,
      loading: true,
      complexObjectName: '',
      lastFilters: {} as any,
      filters: {} as any,
      servicePath: "''",
      isCompactView: false,
      inputTimer: {} as any,
      searchTitle: '',
      filterByDistance: false,
      filterIsActivated: false,
      entities: [] as (Camera | ComplexObject | BolidDevice)[],
      compactViewRegistry: {} as { [key: string]: (val: boolean) => {} },
      currentRoutePath: history.location.pathname,
      previousServicePath: [] as {
        search: string
        title: string
      }[],
      initServicePath: "''",
      permissionScopes: new Map(),
      removeListener: () => {},
      entityTypes: [] as string[],
      needToUpdateRoute: true,
      emitter: { emit: () => {} } as any,
      metaTypes: {} as any,
      unlisten: () => {},
      operatorsRegExp: /~=|==|<=|>=|!=|>|</,
      operatorsDict: new Map([
        ['~=', 'contains'],
        ['==', 'equals'],
        ['<=', 'lte'],
        ['>=', 'gte'],
        ['!=', 'notEquals'],
        ['>', 'gt'],
        ['<', 'lt']
      ])
    }
  },
  computed: {
    isInitServicePath(): Boolean {
      return this.servicePath === this.initServicePath
    },
    nodeEntityTypes(): string[] {
      return this.spaProps.nodeEntityTypes || DEFAULT_NODE_ENTITIES
    },
    leafEntityTypes(): string[] {
      return this.spaProps.leafEntityTypes || DEFAULT_LEAF_ENTITIES
    },
    orderByOptions(): Required<IWidgetProps>['orderByFields'] {
      return this.spaProps.orderByFields || DEFAULT_SORTING_FIELDS
    },
    isQFiltersReady(): boolean {
      return !!(this.spaProps?.filters && this.isListReady)
    }
  },
  watch: {
    searchTitle(val: string) {
      const clearedVal = val.replace(/[^a-zA-Z0-9а-яА-Я-.,\s]/g, '')
      if (this.searchTitle.length === clearedVal.length) {
        ;(val.length > 2 || val.length === 0) &&
          debounce(
            () => {
              this.filters.title = val
            },
            300,
            'search'
          )
      } else {
        this.searchTitle = clearedVal
      }
    },
    isCompactView(val: Boolean | null, prevVal: boolean) {
      if (val === null) {
        this.isCompactView = prevVal
      }
      this.emitter.emit(this.isCompactView ? 'collapse' : 'expand')
    },
    filters: {
      handler(val) {
        if (this.needToUpdateRoute) {
          const queryList: string[] = []
          Object.entries(val).forEach(([key, value]: any[]) => {
            value = Array.isArray(value) ? value.join(',') : value
            if (value.length !== 0) {
              const filter =
                key === 'title' ? `title~=${value}` : `${key}==${value}`
              queryList.push(filter)
            }
          })
          const query = {
            ...SearchParams.parse(history.location.search),
            q: queryList.join(';')
          } as any
          if (queryList.length === 0) {
            this.filterIsActivated = false
            delete query.q
          } else {
            this.filterIsActivated = true
          }
          history.push({
            search: SearchParams.stringify({
              ...query
            })
          })
        }
      },
      deep: true
    },
    offset(val: number) {
      if (this.needToUpdateRoute) {
        this.loading = true
        this.entities = []
        history.push({
          search: SearchParams.stringify({
            ...SearchParams.parse(history.location.search),
            offset: val
          })
        })
      }
    },
    servicePath(val: string) {
      if (this.needToUpdateRoute) {
        const config = Object.entries(
          SearchParams.parse(history.location.search)
        ).reduce<Record<string, any>>((acc, [key, value]) => {
          if (key !== 'servicePath') {
            acc[key] = value
          } else if (val.length > 2) {
            acc[key] = val
          }
          return acc
        }, {})
        if (val === "''") {
          this.complexObjectName = ''
        }
        history.push({
          search: SearchParams.stringify(config)
        })
      }
    }
  },
  async mounted() {
    const query = SearchParams.parse(history.location.search)
    if (query.limit === undefined) {
      const containerWidth: number = (this.$refs.container as HTMLElement)
        .clientWidth
      switch (true) {
        case containerWidth - 1480 < 0:
          this.initLimit = this.limit = 6
          break
        case containerWidth - 1540 < 0:
          this.initLimit = this.limit = 8
          break
        default:
          this.initLimit = this.limit = 10
      }
    }
    this.isCompactView =
      localStorage.getItem('isCompactView') !== null
        ? localStorage.getItem('isCompactView') === 'true'
        : false
    this.$watch('isCompactView', this.isCompactViewWatcher)
    try {
      this.permissionScopes.set(
        null,
        await this.api.getPermissions([
          ...(this.spaProps.filters
            ? collectScopes(this.spaProps.filters)
            : []),
          'ReadAssignment',
          'ReadAssignmentGroup'
        ])
      )
    } catch (error) {
      console.error(error)
    }
    this.emitter = mitt()
    this.$watch('entityTypes', this.entityTypesWatcher)
    let search = JSON.stringify(SearchParams.parse(history.location.search))
    this.unlisten = history.listen(async (location) => {
      const locaSearch = JSON.stringify(SearchParams.parse(location.search))
      if (
        location.pathname === this.currentRoutePath &&
        locaSearch !== search
      ) {
        search = locaSearch
        await this.parseCurrentQuery()
        this.updateAll()
      }
    })
    await this.parseCurrentQuery()
    await this.updateAll()
    this.$watch('limit', this.limitWatcher)
    if ('getNotificationSocket' in this.api) {
      this.removeListener = this.api
        .getNotificationSocket()
        .addListener('BolidDevice', (entity: BolidDevice) => {
          const bolidSubscriptions = JSON.parse(
            localStorage.getItem('bolidSubscriptions') || '[]'
          ) as BolidSubscription[]
          const subscribedDevice = bolidSubscriptions.find(
            ({ deviceId, activationStatus }: BolidSubscription) =>
              deviceId === entity.id &&
              Number(activationStatus) === entity.status
          )
          if (subscribedDevice) {
            const { message, title } = bolidStatuses[entity.status] || {
              message: '',
              title: this.$t('wrongStatus')
            }
            this.$toast.add({
              severity: 'error',
              summary: `${entity.title} - ${title}`,
              detail: message
            })
          }
        })
    }
  },
  beforeDestroy() {
    this.removeListener()
  },
  methods: {
    compactViewSubscriptor(id: string, callback: (val: boolean) => {}) {
      const entity = this.entities.find((e) => e.id === id)
      if (entity !== undefined) {
        this.compactViewRegistry[id] = callback
      }
      return () => {
        delete this.compactViewRegistry[id]
      }
    },
    menuToggle(ref: string, event: EventInit) {
      // @ts-ignore
      // eslint-disable-next-line no-unused-expressions
      this.$refs[ref]?.toggle?.(event)
    },
    errorToast(error: any) {
      console.error(error)
      this.$toast.add({
        severity: 'error',
        detail: error.message,
        life: 3000
      })
    },
    async updateAll() {
      this.loading = true
      this.fetchEntities()
    },
    async goBack() {
      this.loading = true
      this.entities = []
      if (this.previousServicePath.length > 0) {
        const previous = this.previousServicePath.pop()
        history.push({
          search: previous!.search
        })
        this.loading = true
        this.complexObjectName = previous?.title || ''
      } else {
        const { servicePath } =
          this.servicePath !== "''"
            ? await this.fetchServicePath(this.servicePath)
            : { servicePath: this.initServicePath }
        this.servicePath = servicePath || "''"
        this.complexObjectName = ''
      }
    },
    pauseAll() {
      const button = document.querySelectorAll(
        "#camerasAnchor button[data-role='button-pause']"
      ) as NodeListOf<HTMLElement>
      if (button) {
        Array.from(button).forEach((e: HTMLElement) => {
          e.click()
        })
      }
    },
    entityTypesWatcher(val: string[]) {
      if (this.needToUpdateRoute) {
        if (val.length > 0) {
          history.push({
            search: SearchParams.stringify({
              ...SearchParams.parse(history.location.search),
              type: val.join(',')
            })
          })
        } else {
          const query = SearchParams.parse(history.location.search)
          delete query.type
          history.push({
            search: SearchParams.stringify({
              ...query
            })
          })
        }
      }
    },
    async canI(scope: string | string[], id: string | null = null) {
      const checkScopes = Array.isArray(scope) ? scope : [scope]
      const myScopes = this.permissionScopes.get(id)
      if (myScopes) {
        return checkScopes.every((s) => myScopes.includes(s))
      } else {
        if (id) {
          // @ts-ignore
          if (!this.api.getPermissionWithGlobalBatch) return
          try {
            // @ts-ignore
            const permissionMap = (await api.getPermissionWithGlobalBatch([
              {
                entityId: id,
                scopes: checkScopes
              }
            ])) as Map<string, { scopes: string[] }>
            const scopes = permissionMap.get(id)?.scopes
            this.permissionScopes.set(id, scopes)
            return checkScopes.every((s) => scopes?.includes(s))
          } catch (error) {
            console.error(error)
          }
        } else {
          const scopes = this.permissionScopes.get(null)
          return checkScopes.every((s) => scopes?.includes(s))
        }
      }
    },
    isCompactViewWatcher(val: boolean, prevVal: boolean) {
      // Чтобы не откликивался режим просмотра
      if (val !== prevVal) {
        localStorage.setItem('isCompactView', String(val))
        Object.values(this.compactViewRegistry).forEach((expandCollapse) => {
          expandCollapse(val)
        })
      } else {
        this.isCompactView = prevVal
      }
    },
    currentOrder() {
      return this.orderBy?.length
        ? this.orderBy.reduce<Record<string, string>>((acc, next) => {
            const key = next.replace(/^!/, '')
            const order = next[0] === '!' ? 'desc' : 'asc'
            acc[key] = order
            return acc
          }, {})
        : {
            ...(this.spaProps.cubeListQuery?.order || {})
          }
    },
    setOrderBy(value: string[]) {
      this.orderBy = value
    },
    toggleOrder(fieldName: string) {
      if (this.orderBy.includes(fieldName)) {
        this.setOrderBy(
          this.orderBy.map((e: string) => (e === fieldName ? '!' + e : e))
        )
      } else if (this.orderBy.includes('!' + fieldName)) {
        this.setOrderBy(
          this.orderBy.map((e: string) =>
            e === '!' + fieldName ? fieldName : e
          )
        )
      } else {
        this.orderBy.push(fieldName)
      }
      this.pushOrderInSearch()
    },
    dropOrderField(fieldName: string) {
      this.setOrderBy(
        this.orderBy.filter(
          (e: string) => !['!' + fieldName, fieldName].includes(e)
        )
      )
      this.pushOrderInSearch()
    },
    pushOrderInSearch() {
      const query = {
        ...SearchParams.parse(history.location.search),
        orderBy: this.orderBy.join(',')
      } as any
      if (this.orderBy.length === 0) {
        delete query.orderBy
      }
      history.push({
        search: SearchParams.stringify({
          ...query
        })
      })
    },
    sortIcon(field: string) {
      return {
        'mdi-sort-variant':
          !this.orderBy.includes(field) && !this.orderBy.includes('!' + field),
        'mdi-sort-descending': this.orderBy.includes(field),
        'mdi-sort-ascending': this.orderBy.includes('!' + field)
      }
    },
    orderButtonClass(field: string) {
      return this.orderBy.includes(field) || this.orderBy.includes('!' + field)
        ? 'p-button-outlined'
        : 'p-button-blurred'
    },
    getOptionValue(
      options: any[],
      val: string[],
      optionLabel = 'name',
      optionValue = 'id'
    ) {
      const values = val
        ? val
            .map((e) => {
              return (options.find((el) => el[optionValue] === e) || {
                [optionLabel]: this.$t('unknownValue')
              })[optionLabel]
            })
            .join(', ')
        : ''
      return values === '' ? this.$t('all') : values
    },
    limitWatcher(val: number) {
      const newOffset = val * Math.trunc(this.offset / val)
      this.entities = this.entities.slice(0, val)
      if (this.needToUpdateRoute) {
        history.push({
          search: SearchParams.stringify({
            ...SearchParams.parse(history.location.search),
            limit: val,
            offset: newOffset
          })
        })
      }
    },
    openComplexObject({ id, title }: IEntity) {
      this.loading = true
      this.previousServicePath.push({
        search: history.location.search,
        title: this.complexObjectName
      })
      this.complexObjectName = title
      this.entities = []
      history.push({
        search: `servicePath="${id}"`
      })
    },
    async fetchAssignmentsTypes() {
      const options: IListPayload = {
        limiter: { type: 'AssignmentType', limit: 1000 }
      }
      try {
        const { results }: { results: AssignmentType[] } =
          await this.api?.getEntitiesList?.(options)
        this.assignmentTypes = results
      } catch (error) {
        console.error(error)
      }
    },
    async fetchMetadata(keys: string[]) {
      if (!('batchQuery' in this.api)) {
        return []
      }
      try {
        const {
          results,
          entity
        }: { results?: BasicMetadata[]; entity?: BasicMetadata } =
          keys.length > 1
            ? await this.api.batchQuery({
                filter: {
                  entities: keys.map((type) => ({
                    id: `EntityTypeMetadata:${type}`,
                    type: 'EntityTypeMetadata'
                  }))
                },
                limiter: { limit: 1000 }
              })
            : await this.api.getEntity({
                id: `EntityTypeMetadata:${keys[0]}`,
                type: 'EntityTypeMetadata'
              })
        ;(results || [entity]).forEach((metadata) => {
          if (metadata) {
            this.metaTypes[metadata.entityType] = metadata
          }
        })
      } catch (error) {
        console.error(error)
        return []
      }
    },
    async fetchAssignmentsList(cameraIds: string) {
      const options: IListPayload = {
        limiter: { type: 'Assignment', limit: 1000 }
      }
      cameraIds &&
        (options.filter = {
          q: [{ key: 'entityId', value: cameraIds, operator: '==' }]
        })
      try {
        const { results }: { results: IEntity[] } =
          await this.api.getEntitiesList(options)
        if ('getPermissionsByIdsMap' in this.api) {
          this.permissionScopes = new Map([
            ...this.permissionScopes,
            ...(await this.api.getPermissionsByIdsMap(
              results.map((e: IEntity) => e.id)
            ))
          ])
        }
        this.analytics = results
      } catch (error) {
        this.errorToast(error)
      }
    },
    async fetchAssignmentGroups(cameraIds: string) {
      const options: IListPayload = {
        limiter: { type: 'AssignmentGroup', limit: 1000 }
      }
      cameraIds &&
        (options.filter = {
          q: [{ key: 'entityId', value: cameraIds, operator: '==' }]
        })
      try {
        const { results }: { results: IEntity[] } =
          await this.api.getEntitiesList(options)
        if ('getPermissionsByIdsMap' in this.api) {
          this.permissionScopes = new Map([
            ...this.permissionScopes,
            ...(await this.api.getPermissionsByIdsMap(
              results.map((e: IEntity) => e.id)
            ))
          ])
        }

        this.assignmentGroups = results
      } catch (error) {
        this.errorToast(error)
      }
    },
    async fetchEntities() {
      if (!this.spaParent?.props?.cubeListQuery) {
        throw new Error("'cubeListQuery' property is not defined")
      }
      this.loading = true
      this.isPaginationReady = false
      const isInitialFetch = this.entities.length === 0
      const { q, servicePath } = SearchParams.parse(history.location.search)
      const filters = this.spaParent.props.cubeListQuery?.filters || []
      this.lastFilters = filters
      const order = this.currentOrder()
      const currentRequestMarker = {}
      const cubeName = this.spaParent.props?.cubeName || DEFAULT_CUBE_NAME
      if (typeof q === 'string') {
        q.split(';').forEach((qPair) => {
          const [key, value] = qPair.split(this.operatorsRegExp)
          const operator = qPair.match(this.operatorsRegExp)
          const values = value
            .split(',')
            .map((val) => [
              val,
              val.toUpperCase(),
              val.toLowerCase(),
              val[0].toUpperCase() + val.substring(1).toLowerCase()
            ])
            .flat()
          filters.push({
            member: `${cubeName}.${key}`,
            operator: this.operatorsDict.get(operator?.[0] || '~='),
            values
          })
        })
      } else if (this.spaProps.nodeEntityTypes?.length) {
        filters.push({
          member: `${cubeName}.servicePath`,
          operator: 'equals',
          values: ['']
        })
      }

      if (this.entityTypes.length) {
        filters.push({
          member: `${cubeName}.type`,
          operator: 'equals',
          values: this.entityTypes
        })
      }

      if (typeof servicePath === 'string' && servicePath.length > 2) {
        const servicePathProp = filters.find(
          // @ts-ignore
          ({ member }) => member.includes('servicePath')
        )
        if (servicePathProp) {
          servicePathProp.values = [
            servicePath,
            ...(typeof q === 'string' && q?.includes('title')
              ? this.entities.map(({ id }) => id)
              : [])
          ]
        } else {
          filters.push({
            member: `${cubeName}.servicePath`,
            operator: 'equals',
            values: [
              servicePath,
              ...(typeof q === 'string' && q?.includes('title')
                ? this.entities.map(({ id }) => id)
                : [])
            ]
          })
        }
      }

      this.currentRequestMarker = currentRequestMarker
      let entities: (ComplexObject | Camera)[] = []
      try {
        if ('cubeGetEntities' in this.api) {
          const { results }: { results: (ComplexObject | Camera)[] } =
            (await this.api?.cubeGetEntities?.({
              ...this.spaParent.props.cubeListQuery,
              offset: this.offset,
              limit: this.limit,
              filters,
              order
            })) || { results: [] }
          if (
            isInitialFetch &&
            String(q).includes('title') &&
            typeof servicePath === 'string' &&
            servicePath.length > 2
          ) {
            const spFilter = filters.find(
              ({ member }: { member: string }) =>
                member === `${cubeName}.servicePath`
            )
            if (spFilter) {
              spFilter.values.push(...results.map((e) => e?.id))
            }
            try {
              const { results: nestedResults = [] }: { results: Camera[] } =
                (await this.api?.cubeGetEntities?.({
                  ...this.spaParent.props.cubeListQuery,
                  offset: this.offset,
                  limit: this.limit,
                  filters,
                  order
                })) || { results: [] }
              entities = nestedResults
            } catch (error) {
              console.error(error)
            }
          }
          entities = results
        }
        const entityMap = new Map()
        entities.forEach((el) => {
          entityMap.has(el.type)
            ? entityMap.set(el.type, [...entityMap.get(el.type), el.id])
            : entityMap.set(el.type, [el.id])
        })
        const newEntitiesKeys: string[] = Array.from(entityMap.keys()).filter(
          (type) => !this.metaTypes[type]
        )
        if ('getPermissionsByIdsMap' in this.api) {
          this.permissionScopes = new Map([
            ...this.permissionScopes,
            ...(await this.api.getPermissionsByIdsMap(
              entities.map((e) => e.id)
            ))
          ])
        }
        if (newEntitiesKeys.length) {
          if (newEntitiesKeys.includes('Camera')) {
            this.fetchAssignmentsTypes()
          }
          await this.fetchMetadata(newEntitiesKeys)
        }
        if (
          entityMap.has('Camera') &&
          this.permissionScopes.get(null).includes('ReadAssignment')
        ) {
          const cameraIds = entityMap.get('Camera').join(',')
          await Promise.all([
            this.fetchAssignmentGroups(cameraIds),
            this.fetchAssignmentsList(cameraIds)
          ])
        }
        this.entities = entities.filter(
          (entity) =>
            entity.type !== 'BolidDevice' ||
            typeof entity.deviceId !== 'undefined'
        )
        this.currentRequestMarker === currentRequestMarker &&
          (this.loading = false)
        if (this.lastFilters === filters) {
          setTimeout(() => {
            this.isListReady = true
          }, 1000)
          this.isPaginationReady = true
          this.count = await this.fetchCount(filters)
        }
      } catch (error) {
        console.error(error)
      }
    },
    async parseCurrentQuery() {
      const query = SearchParams.parse(history.location.search)
      this.needToUpdateRoute = false
      const parsedFilters = { ...CLEAR_FILTERS } as any
      if (query.q !== undefined) {
        const options = (query.q as string).split(';')
        options.forEach((e: string) => {
          let [key, value] = e.split('==')
          if (value === undefined) [key, value] = key.split('~=')
          // Имя не массив
          switch (key) {
            case 'title':
              if (value !== '') {
                this.searchTitle = decodeURIComponent(value)
                parsedFilters.title = this.searchTitle
              }
              break
            default: {
              // Может быть массив значений через запятую
              const values = value.split(',')
              // Парсим числа как числа, а не как строки
              parsedFilters[key] = values.some((el: string) =>
                isNaN(Number(el))
              )
                ? values
                : values.map((el: string) => Number(el))
              break
            }
          }
        })
        if (!parsedFilters.title) {
          this.searchTitle = ''
        }
      } else {
        this.searchTitle = ''
      }
      this.servicePath =
        query.servicePath !== undefined && this.servicePath !== ''
          ? (query.servicePath as string)
          : this.initServicePath
      this.limit =
        query.limit !== undefined &&
        !isNaN(Number(query.limit)) &&
        Number(query.limit) > 0
          ? Math.abs(Number(query.limit))
          : this.initLimit
      this.offset =
        query.offset !== undefined && !isNaN(Number(query.offset))
          ? Math.abs(Number(query.offset))
          : 0
      this.setOrderBy(
        query.orderBy !== undefined ? (query.orderBy as string).split(',') : []
      )
      this.entityTypes =
        query.type !== undefined ? (query.type as string).split(',') : []
      if (this.servicePath !== "''" && this.complexObjectName === '') {
        const { title } = await this.fetchServicePath(this.servicePath)
        this.complexObjectName = title
      }
      this.filters = { ...parsedFilters }
      this.needToUpdateRoute = true
    },
    async fetchServicePath(id: string) {
      try {
        if (!('batchQuery' in this.api)) {
          return {}
        }
        const { results } = await this.api.batchQuery({
          filter: {
            entities: this.nodeEntityTypes.map((type: string) => ({
              id,
              type
            }))
          },
          limiter: { limit: 1000 }
        })
        return results[0] ?? {}
      } catch (error) {
        this.errorToast(error)
        return {}
      }
    },
    isClearable() {
      const qSearchKeys = new Set(
        Object.keys(SearchParams.parse(history.location.search))
      )
      return qSearchKeys.has('offset')
        ? qSearchKeys.size > 1
        : qSearchKeys.size >= 1
    },
    cleanFilter() {
      this.limit = this.initLimit

      this.$nextTick(() => {
        history.push({
        search: SearchParams.stringify({})
      })
      })
    }
  }
})
