import Vue from 'vue'
import { mapStateTyped } from '@/store'
import { CubeFilter } from '@netvision/lib-api-repo'

const DEFAULT_CUBE_NAME = 'Objects'

export default Vue.extend({
  name: 'Mixins',
  computed: {
    ...mapStateTyped(['spaParent', 'spaProps', 'api'])
  },
  methods: {
    async fetchCount(filters: CubeFilter[]) {
      if (!('cubeGetQuantity' in this.api)) {
        throw new Error("cubeGetQuantity method isn't implemented")
      }

      try {
        const count = await this.api?.cubeGetQuantity?.({
          dimensions: [],
          timeDimensions: [],
          measures: [`${this.spaProps.cubeName || DEFAULT_CUBE_NAME}.count`],
          filters
        })
        return count || 0
      } catch (error) {
        console.error(error)
        return 0
      }
    }
  }
})
