import { TUnionRepo } from '@netvision/lib-api-repo'
import { TApiLib } from './shims-tsx'
import { mapState, mapMutations } from 'vuex'

interface IState {
  spaParent: any
  spaProps: IWidgetProps & TApiLib
  api: TUnionRepo
}

const initialState = (): IState => {
  return {
    spaParent: {},
    spaProps: {} as IWidgetProps & TApiLib,
    api: {} as TUnionRepo
  } as const
}
type State = ReturnType<typeof initialState>

export const mapStateTyped = <
  T extends keyof State,
  G extends { [Key in T]: () => State[Key] },
>(
  keys: T[]
): G => {
  return { ...mapState(keys) } as G
}

const mutations = {
  setValue<T extends keyof State>(state: State, [key, value]: [T, State[T]]) {
    state[key] = value
  },
  reset(state: State) {
    const s = initialState()
    Object.keys(s).forEach((key) => {
      // @ts-ignore
      state[key] = s[key]
    })
  }
}

type Mutations = typeof mutations

export const mapMutationsTyped = <
  T extends keyof Mutations,
  G extends {
    [K in T]: (I?: Parameters<Mutations[K]>[1]) => ReturnType<Mutations[K]>
  },
>(
  keys: T[]
): G => {
  return mapMutations(keys) as G
}

export default {
  state: initialState(),
  mutations
}
